import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import Layout from "./../../components/Layout/";
import Loading from "./../../components/Loading";

import ModalConsult from "./../../components/ModalConsult/";
import ModalUpdate from "./../../components/ModalUpdate/";
import ModalSchedule from "./../../components/ModalSchedule";

import api from "./../../services/api";

import refreshToken from "./../../utils/refreshToken";

import { useAuth } from "./../../hooks/auth";
import { useToast } from "./../../hooks/toast";

import {
  Container,
  Content,
  ErrorMessageContainer,
  OptionToUnlock,
  OptionToScheduleOrLock,
  OptionToLockOrConsult,
  SchedulesContainer,
  SchedulesContent,
  TDSchedules,
  WeekdayTitle,
} from "./styles";
import OutsideClickHandler from "react-outside-click-handler";
import ModalToDemarcateSchedule from "../../components/ModalToDemarcateSchedule";
import { useRefresh } from "../../hooks/refresh";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ModalType from "./ModalType";
import ScheduleType from "./ScheduleType";
import { BiChevronDown, BiChevronUp, BiVideo } from "react-icons/bi";
import { MdLockOutline, MdPersonOutline } from "react-icons/md";
import ModalUpdateUser from "../../components/ModalUpdateUser";

interface IConsultants {
  id: number;
  first_name: string;
  city: string;
}

interface ICommercialRegion {
  relevance_order: number;
  name: string;
}

interface ISchedules {
  consultant_id: number;
  date: string;
  status: number;
  video_call: number;
}

interface IPropertyDetails {
  propertyId: number;
  clientName: string;
}

interface IData {
  dates: string[];
  bussiness_hours: string[];
  schedules: ISchedules[];
  consultants: IConsultants[];
  commercial_region: ICommercialRegion[];
}

const DAYS_OF_THE_WEEK = [
  "DOMINGO",
  "SEGUNDA-FEIRA",
  "TERÇA-FEIRA",
  "QUARTA-FEIRA",
  "QUINTA-FEIRA",
  "SEXTA-FEIRA",
  "SÁBADO",
];

const getDayOfTheWeek = (date: string) => {
  const [day, month, year] = date.split("/");
  const newDate = new Date(Number(year), Number(month) - 1, Number(day));
  return DAYS_OF_THE_WEEK[newDate.getDay()];
};

function formatDateToOption(scheduleOption: string) {
  const [date, time] = scheduleOption.split("|");

  const [year, month, day] = date.split("-");
  const dayOfTheWeek = new Date(
    Number(year),
    Number(month) - 1,
    Number(day)
  ).getDay();

  const writtenDayOfTheWeek = DAYS_OF_THE_WEEK[dayOfTheWeek].substring(0, 3);

  return `${writtenDayOfTheWeek} ${day}/${month}/${year.substring(
    2,
    4
  )} - ${time.substring(0, 5)}`;
}

const Schedule: React.FC = () => {
  const history = useHistory();

  const { access_token, user, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const { refresh, handleRefresh } = useRefresh();

  const [fetchedData, setFetchedData] = useState<IData>({
    bussiness_hours: [],
    commercial_region: [],
    dates: [],
    consultants: [],
    schedules: [],
  });

  const [dateSelected, setDateSelected] = useState<string[]>([]);

  const [initialStatus, setInitialStatus] = useState<number>();
  const [schedule, setSchedule] = useState("");

  const [mouseIsDown, setMouseIsDown] = useState(false);

  const [optionCoordinates, setOptionCoordinates] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [shouldOpenOption, setShouldOpenOption] = useState<{
    shouldOpen: boolean;
    date: string;
  }>({ shouldOpen: false, date: "" });

  const [loadingSchedules, setLoadingSchedules] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const [loadingRequestToLockDate, setLoadingRequestToLockDate] =
    useState(false);

  const [loadApiError, setLoadApiError] = useState("");

  const [loadModal, setLoadModal] = useState<{
    modalToOpen: string;
    param: any;
  }>({
    modalToOpen: "",
    param: "",
  });

  const [selectedScheduleTable, setSelectedScheduleTable] = useState(0);

  const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>();
  const [loadingPropertyDetails, setLoadingPropertyDetails] =
    useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    async function loadApi() {
      try {
        api.defaults.headers.authorization = `Bearer ${access_token}`;

        const response = await api.get("/api/adm/schedules");

        const {
          bussiness_hours,
          commercial_region,
          consultants,
          schedules,
          dates,
        } = response.data.data;

        const orderedCommercialRegions = commercial_region.sort(
          (reg1: ICommercialRegion, reg2: ICommercialRegion) =>
            reg1.relevance_order - reg2.relevance_order
        );

        setFetchedData({
          dates,
          bussiness_hours,
          commercial_region: orderedCommercialRegions,
          consultants: [...consultants],
          schedules,
        });

        setLoadingSchedules(false);
      } catch (err: any) {
        if (err?.response?.status === 403) {
          setLoadApiError(err?.response?.data?.message);

          setLoadingSchedules(true);
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadApiError(err.response.data.message);

        setLoadingSchedules(true);
      }
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }

    // eslint-disable-next-line
  }, [dateToExpires, access_token, user, history, refresh]);

  const selectDate = async (
    scheduleOption: string,
    status: number,
    event: React.MouseEvent
  ) => {
    setOptionCoordinates((prevCoords) => ({
      ...prevCoords,
      x: event.pageX + (isMobile ? -10 : 10),
      y: event.pageY + (isMobile ? -10 : 10),
    }));

    if (status === initialStatus) {
      const checkIfAlreadyIsSelect = dateSelected.find(
        (date) => date === scheduleOption
      );

      if (!!checkIfAlreadyIsSelect) {
        const removeId = dateSelected.filter((date) => date !== scheduleOption);

        return setDateSelected(removeId);
      }
      setDateSelected((prevDatesSelected) => [
        ...prevDatesSelected,
        scheduleOption,
      ]);
    } else {
      setDateSelected([]);

      setDateSelected([scheduleOption]);
    }

    setShouldOpenOption((prevOption) => ({
      ...prevOption,
      shouldOpen: true,
      date: formatDateToOption(scheduleOption),
    }));
  };

  const selectDateMouseOver = (
    scheduleOption: string,
    event: React.MouseEvent
  ) => {
    if (!!mouseIsDown && initialStatus === ScheduleType.LOCKED) {
      setOptionCoordinates((prevCoords) => ({
        ...prevCoords,
        x: event.pageX + (isMobile ? -10 : 10),
        y: event.pageY + (isMobile ? -10 : 10),
      }));

      if (shouldOpenOption.shouldOpen) {
        setShouldOpenOption((prevOption) => ({
          ...prevOption,
          shouldOpen: false,
        }));
      }

      const checkIfAlreadyIsSelect = dateSelected.find(
        (date) => date === scheduleOption
      );

      if (!!checkIfAlreadyIsSelect) {
        const removeId = dateSelected.filter((date) => date !== scheduleOption);

        return setDateSelected(removeId);
      }

      setDateSelected((prevDatesSelected) => [
        ...prevDatesSelected,
        scheduleOption,
      ]);
    }
  };

  const handleUnlockDate = async () => {
    setLoadingRequest(true);

    try {
      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post("/api/adm/schedules", {
        schedules: [...dateSelected],
      });

      if (!!response.data.success) {
        addToast({
          title:
            dateSelected.length > 1
              ? "Datas desbloqueadas"
              : "Data desbloqueada",
          type: "success",
          description: "",
        });

        handleRefresh();
        setDateSelected([]);
      }

      setLoadingRequest(false);
    } catch (err: any) {
      console.log(err);

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      setLoadingRequest(false);
    }
  };

  const handleLockDate = async () => {
    setLoadingRequestToLockDate(true);

    try {
      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.put("/api/adm/schedules", {
        schedule: dateSelected.join(),
        status: ScheduleType.LOCKED,
      });

      if (!!response.data.success) {
        addToast({
          title: "Data bloqueada",
          type: "success",
          description: "",
        });

        handleRefresh();
        setDateSelected([]);
      }

      setLoadingRequestToLockDate(false);
    } catch (err: any) {
      console.log(err);

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      setLoadingRequestToLockDate(false);
    }
  };

  const handleCloseModal = () => {
    setLoadModal((prevValue) => ({
      ...prevValue,
      modalToOpen: ModalType.NONE,
      param: "",
    }));
  };

  const handleOpenModal = (identifier: string, param: any) => {
    setLoadModal((prevValue) => ({
      ...prevValue,
      modalToOpen: identifier,
      param,
    }));
  };

  const toggleScheduleTable = (index: any) => {
    setSelectedScheduleTable(index);
  };

  async function selectPropertyDetails(schedule: string) {
    setLoadingPropertyDetails(true);
    try {
      const fetchedSchedule = await api.get(`/api/adm/schedules/${schedule}`);
      const clientName = fetchedSchedule.data.data.client.first_name;

      const propertyId = fetchedSchedule.data.data.property.id;

      setPropertyDetails({ propertyId, clientName });
    } catch (err) {
      console.log(err);
    }
    setLoadingPropertyDetails(false);
  }

  return (
    <>
      {!!loadingSchedules ? (
        <Loading />
      ) : (
        <Container
          onMouseUp={() => {
            setMouseIsDown(false);
            setShouldOpenOption((prevOption) => ({
              ...prevOption,
              shouldOpen: true,
            }));
          }}
        >
          <Layout>
            <Content>
              <>
                {!!loadApiError && (
                  <ErrorMessageContainer>
                    <h2>{loadApiError}</h2>
                  </ErrorMessageContainer>
                )}

                {!loadApiError && (
                  <>
                    <OutsideClickHandler
                      onOutsideClick={() =>
                        setDateSelected((prevValue) =>
                          shouldOpenOption.shouldOpen && !mouseIsDown
                            ? []
                            : [...prevValue]
                        )
                      }
                    >
                      {shouldOpenOption.shouldOpen &&
                      !mouseIsDown &&
                      initialStatus === ScheduleType.LOCKED &&
                      dateSelected.length > 0 ? (
                        <OptionToUnlock
                          onClick={handleUnlockDate}
                          coordinate={optionCoordinates}
                        >
                          <p>
                            {!!loadingRequest
                              ? "Carregando..."
                              : "Desbloquear horário"}
                          </p>
                        </OptionToUnlock>
                      ) : (
                        <></>
                      )}

                      {shouldOpenOption.shouldOpen &&
                      !mouseIsDown &&
                      initialStatus === ScheduleType.UNLOCKED &&
                      dateSelected.length > 0 ? (
                        <OptionToScheduleOrLock coordinate={optionCoordinates}>
                          <p>{shouldOpenOption.date}</p>
                          <div className="options-container">
                            <button
                              onClick={() =>
                                handleOpenModal(ModalType.SCHEDULE, schedule)
                              }
                            >
                              Agendar visita
                            </button>
                            <button onClick={handleLockDate}>
                              {!!loadingRequestToLockDate
                                ? "Carregando..."
                                : "Bloquear horário"}
                            </button>
                          </div>
                        </OptionToScheduleOrLock>
                      ) : (
                        <></>
                      )}

                      {shouldOpenOption.shouldOpen &&
                      !mouseIsDown &&
                      initialStatus === ScheduleType.SCHEDULED &&
                      dateSelected.length > 0 ? (
                        <OptionToLockOrConsult coordinate={optionCoordinates}>
                          <p className="option-date">{shouldOpenOption.date}</p>
                          <div className="options-container">
                            <button
                              onClick={() =>
                                handleOpenModal(ModalType.CONSULT, schedule)
                              }
                            >
                              Detalhes da visita
                            </button>

                            <button
                              onClick={() =>
                                handleOpenModal(ModalType.UPDATE, {
                                  schedule,
                                  consultants: fetchedData.consultants,
                                })
                              }
                            >
                              Alterar agendamento
                            </button>

                            <button
                              onClick={() =>
                                handleOpenModal(ModalType.DEMARCATE, [schedule])
                              }
                            >
                              Desmarcar visita
                            </button>
                          </div>
                          <p className="client-tag">
                            {loadingPropertyDetails
                              ? "Carregando..."
                              : `${propertyDetails?.clientName}`}
                          </p>
                        </OptionToLockOrConsult>
                      ) : (
                        <></>
                      )}
                    </OutsideClickHandler>

                    {loadModal.modalToOpen === ModalType.CONSULT && (
                      <ModalConsult
                        open={loadModal.modalToOpen === ModalType.CONSULT}
                        functionToClose={handleCloseModal}
                        functionToDemarcate={() =>
                          handleOpenModal(ModalType.DEMARCATE, [schedule])
                        }
                        functionToUpdate={() =>
                          handleOpenModal(ModalType.UPDATE, {
                            schedule,
                            consultants: fetchedData.consultants,
                          })
                        }
                        functionToUpdateUser={handleOpenModal}
                        param={loadModal.param}
                      />
                    )}

                    {loadModal.modalToOpen === ModalType.UPDATE && (
                      <ModalUpdate
                        open={loadModal.modalToOpen === ModalType.UPDATE}
                        functionToClose={handleCloseModal}
                        param={loadModal.param}
                      />
                    )}

                    {loadModal.modalToOpen === ModalType.SCHEDULE && (
                      <ModalSchedule
                        open={loadModal.modalToOpen === ModalType.SCHEDULE}
                        functionToCloseModalSchedule={handleCloseModal}
                        param={loadModal.param}
                      />
                    )}

                    {loadModal.modalToOpen === ModalType.UPDATE_USER && (
                      <ModalUpdateUser
                        open={loadModal.modalToOpen === ModalType.UPDATE_USER}
                        onReturn={handleOpenModal}
                        param={loadModal.param}
                      />
                    )}

                    <ModalToDemarcateSchedule
                      open={loadModal.modalToOpen === ModalType.DEMARCATE}
                      dateSelected={loadModal.param}
                      functionToCloseModal={handleCloseModal}
                    />

                    <SchedulesContainer>
                      <h3>Agenda de Visitas</h3>
                      <p className="bottom-line"></p>

                      {fetchedData.dates.map((date, index) => (
                        <SchedulesContent key={date}>
                          <WeekdayTitle
                            onClick={() => toggleScheduleTable(index)}
                          >
                            <h4>
                              {getDayOfTheWeek(date)} - {date}
                            </h4>

                            {selectedScheduleTable === index ? (
                              <BiChevronUp />
                            ) : (
                              <BiChevronDown />
                            )}
                          </WeekdayTitle>

                          {fetchedData.commercial_region.map((city) => {
                            const hasConsultant =
                              !!fetchedData.consultants.find(
                                (consultant) => consultant.city === city.name
                              );
                            if (!hasConsultant) return null;
                            return (
                              <table
                                className={
                                  selectedScheduleTable === index
                                    ? "table-show"
                                    : "table-hide"
                                }
                                cellPadding={0}
                                cellSpacing={0}
                                key={city.relevance_order}
                              >
                                <thead>
                                  <tr>
                                    <th className="th-city">{city.name}</th>
                                    {fetchedData.bussiness_hours.map((hour) => (
                                      <th className="th-hour" key={hour}>
                                        {hour}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody key={city.name}>
                                  {fetchedData.consultants.map((consultant) => {
                                    if (consultant.city !== city.name)
                                      return null;
                                    return (
                                      <tr key={consultant.id}>
                                        <td className="td-consultant">
                                          <p>{consultant.first_name}</p>
                                        </td>
                                        {fetchedData.bussiness_hours.map(
                                          (hour) => {
                                            const [day, month, year] =
                                              date.split("/");
                                            const possibleSchedule =
                                              fetchedData.schedules.find(
                                                (schedule) =>
                                                  schedule.date ===
                                                    `${year}-${month}-${day} ${hour}:00` &&
                                                  schedule.consultant_id ===
                                                    consultant.id
                                              );

                                            const scheduleOption = {
                                              date: `${year}-${month}-${day}|${hour}:00|${consultant.id}`,
                                              status: !!possibleSchedule
                                                ? possibleSchedule.status
                                                : ScheduleType.LOCKED,
                                              video_call: !!possibleSchedule
                                                ? possibleSchedule.video_call
                                                : 0,
                                            };

                                            const isSelected =
                                              dateSelected.findIndex(
                                                (date) =>
                                                  date === scheduleOption.date
                                              );

                                            return (
                                              <TDSchedules
                                                key={scheduleOption.date}
                                                status={scheduleOption.status}
                                                isSelected={isSelected}
                                                onMouseDown={(event) => {
                                                  setInitialStatus(
                                                    scheduleOption.status
                                                  );
                                                  selectDate(
                                                    scheduleOption.date,
                                                    scheduleOption.status,
                                                    event
                                                  );
                                                  setSchedule(
                                                    `${scheduleOption.date}`
                                                  );
                                                  if (
                                                    scheduleOption.status ===
                                                    ScheduleType.SCHEDULED
                                                  ) {
                                                    selectPropertyDetails(
                                                      scheduleOption.date
                                                    );
                                                  }
                                                  setMouseIsDown(true);
                                                }}
                                                onMouseOver={
                                                  scheduleOption.status ===
                                                  ScheduleType.LOCKED
                                                    ? (event) =>
                                                        selectDateMouseOver(
                                                          scheduleOption.date,
                                                          event
                                                        )
                                                    : () => {}
                                                }
                                              >
                                                {scheduleOption.status ===
                                                  ScheduleType.LOCKED && (
                                                  <MdLockOutline size="1.5rem" />
                                                )}
                                                {scheduleOption.status ===
                                                ScheduleType.SCHEDULED ? (
                                                  scheduleOption.video_call ===
                                                  0 ? (
                                                    <MdPersonOutline size="1.75rem" />
                                                  ) : (
                                                    <BiVideo size="1.75rem" />
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </TDSchedules>
                                            );
                                          }
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            );
                          })}
                        </SchedulesContent>
                      ))}
                    </SchedulesContainer>
                  </>
                )}
              </>
            </Content>
          </Layout>
        </Container>
      )}
    </>
  );
};

export default Schedule;
