import React, { useState } from "react";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {
  Content,
  TitleContainer,
  VideoCallContainer,
  ButtonContainer,
  FieldTitle,
  FieldContent,
} from "./styles";

import { FiChevronDown, FiX } from "react-icons/fi";
import useModalFetch from "../../hooks/useModalFetch";
import ModalSkeleton from "../ModalSkeleton";
import ModalType from "../../pages/Schedules/ModalType";
import { MdEdit } from "react-icons/md";
import AddressLink from "./AddressLink";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Guarantee, Property } from "../../global/types";

interface IModalConsult {
  open: boolean;
  functionToClose: () => void;
  functionToDemarcate: () => void;
  functionToUpdateUser: (identifier: string, param: any) => void;
  functionToUpdate: () => void;
  param: string;
}

const formatDate = (schedule: string) => {
  const date = new Date(schedule);

  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()} - ${date.getHours()}:00`;
};

const formatAddress = (property: Property) => {
  return `${property.address} ${property.number ?? ""} ${
    property.neighborhood
  } ${property.complement ? "," + property.complement : ""} | ${
    property.city
  } - ${property.uf}`;
};

function formatGuaranteesList(guarantees: Guarantee[] = []) {
  if (guarantees.length === 0)
    return "O imóvel não possui garantias cadastradas";
  let guaranteeStr = "";

  for (let i = 0; i < guarantees.length; i++) {
    guaranteeStr += guarantees[i].name;
    if (i < guarantees.length - 1) guaranteeStr += ", ";
  }
  return guaranteeStr;
}

const ModalConsult: React.FC<IModalConsult> = ({
  open,
  functionToClose,
  functionToDemarcate,
  functionToUpdateUser,
  functionToUpdate,
  param,
}) => {
  const { fetchedData, isFetching, error } = useModalFetch(
    `/api/adm/schedules/${param}`
  );
  const [expanded, setExpanded] = useState("panel1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const property = fetchedData.property;
  const consultant = fetchedData.consultant;

  const mobileModalStyles = {
    margin: 0,
    width: "100%",
  };

  function handleOpenAccordion(value: string) {
    setExpanded(value);
  }

  let content = <ModalSkeleton />;
  if (!isFetching && Object.keys(fetchedData).length !== 0)
    content = (
      <Content>
        <TitleContainer>
          <h3>DETALHES DA VISITA</h3>
        </TitleContainer>
        {!!fetchedData.video_call && (
          <VideoCallContainer>
            <p>Visita via video chamada</p>
          </VideoCallContainer>
        )}
        <img src={property.photo ?? undefined} alt="Foto do imóvel" />

        <FieldContent>
          <FieldTitle>AGENDAMENTO</FieldTitle>
          <p>
            <b>Data e horário: </b> {formatDate(fetchedData.schedule.date)}
          </p>
          <p>
            <b>Endereço: </b>{" "}
            <AddressLink
              address={formatAddress(fetchedData.property)}
              service="waze"
            />
          </p>
          <p>
            <b>Consultor: </b> {consultant.first_name} - {consultant.phone}
          </p>
        </FieldContent>
        <FieldContent>
          <FieldTitle>
            INQUILINO{" "}
            <MdEdit
              onClick={() =>
                functionToUpdateUser(ModalType.UPDATE_USER, {
                  ...fetchedData.client,
                  schedule: param,
                })
              }
            />
          </FieldTitle>
          <p>
            <b>Nome: </b> {fetchedData.client.first_name}
          </p>
          <p>
            <b>Email: </b> {fetchedData.client.email}
          </p>
          <p>
            <b>Telefone: </b> {fetchedData.client.phone}
          </p>
        </FieldContent>
        <FieldContent>
          <FieldTitle>
            PROPRIETÁRIO{" "}
            <MdEdit
              onClick={() =>
                functionToUpdateUser(ModalType.UPDATE_USER, {
                  ...fetchedData.owner,
                  schedule: param,
                })
              }
            />
          </FieldTitle>
          {fetchedData.owner && (
            <>
              <p>
                <b>Nome: </b> {fetchedData.owner.first_name}
              </p>
              <p>
                <b>Email: </b> {fetchedData.owner.email}
              </p>
              <p>
                <b>Telefone: </b> {fetchedData.owner.phone}
              </p>
            </>
          )}
          {!fetchedData.owner && <p>Não há proprietário cadastrado.</p>}
        </FieldContent>
        <FieldContent>
          <FieldTitle>IMÓVEL</FieldTitle>

          {fetchedData.owner && (
            <>
              <p>
                <b>Código: </b>{" "}
                <a
                  href={`https://www.7cantos.com/imovel/${property.id}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {property.id}
                </a>
              </p>
              <p>
                <b>Garantias: </b>{" "}
                {formatGuaranteesList(fetchedData.property.guarantee)}
              </p>
              <p>
                <b>Admininstrado pela 7cantos? </b>{" "}
                {fetchedData.property.is_adm ? "Sim" : "Não"}
              </p>
              <p>
                <b>Observações: </b>{" "}
                {fetchedData.property.note ?? "Não há observações"}
              </p>
            </>
          )}
          {!fetchedData.owner && <p>Não há proprietário cadastrado.</p>}
        </FieldContent>

        <ButtonContainer>
          <button className="btn-close" onClick={functionToDemarcate}>
            DESMARCAR
          </button>
          <button className="btn-edit" onClick={functionToUpdate}>
            ALTERAR
          </button>
        </ButtonContainer>
      </Content>
    );

  return (
    <Modal
      open={open}
      onClose={functionToClose}
      classNames={{
        modal: "modalConsult",
      }}
      closeIcon={<FiX size={18} color="#000" />}
      styles={
        isMobile
          ? {
              modal: { maxWidth: "400px" },
            }
          : undefined
      }
    >
      {content}
    </Modal>
  );
};

export default ModalConsult;
